// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  background-color: rgb(248, 248, 255);
}

body {
  margin: 0;
  padding: 0;
}

.ant-form-item .ant-form-item-explain {
  font-size: 14px;
}

.ant-picker-dropdown {
  visibility: hidden;
}

.ant-popover {
  z-index: 999;
}

.ant-modal .ant-modal-title {
  font-size: 16px;
}

@keyframes timer-revisao-excluir {
  from {
    width: 0;
  }
  to {
    width: 105px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF","sourcesContent":["html {\n  background-color: rgb(248, 248, 255);\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n}\n\n.ant-form-item .ant-form-item-explain {\n  font-size: 14px;\n}\n\n.ant-picker-dropdown {\n  visibility: hidden;\n}\n\n.ant-popover {\n  z-index: 999;\n}\n\n.ant-modal .ant-modal-title {\n  font-size: 16px;\n}\n\n@keyframes timer-revisao-excluir {\n  from {\n    width: 0;\n  }\n  to {\n    width: 105px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
